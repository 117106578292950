<template>
    <div id="my">
         <van-notice-bar :scrollable="false" background="#eee" color="#000">我的预约
            </van-notice-bar>
                 <van-list
                    v-model="loading"
                    :finished="orderfinished"
                    finished-text="没有更多了"
                   
                    >
                    <van-cell
                        v-for="item in orderlist"
                        :key="item.mainKay"
                    >
                    <div class="imgbox">
                        <img src="../static/image/book2.png" alt="">
                    </div>
                    <div class="infobox">
                        <p>图书名：{{item.title}}</p>
                        <p>作者：{{item.auther}}</p>
                        <p>索书号：{{item.callNo}}</p>
                        <p>预约日期：{{item.createTime}}</p>
                        <p><van-button size="small" type="info" @click="cancelOrder(item)">取消</van-button> </p>
                    </div>
                    </van-cell>
                </van-list>
       
    </div>
    
</template>
<script>
import { myorder,cancelorder} from "@/api/goods.js";
export default {
    data(){
        return {
            orderlist:[],
            reservelist:[],
            loading: false,
            orderfinished: false,
            reservefinished: false,
        }
    },
    created(){
       this.getMyOrder()
    },
    methods:{
         onLoad(){
      // 异步更新数据
            setTimeout(() => {
                for (let i = 0; i < 1; i++) {
                // this.list.push(this.list.length + 1);
                }
                // 加载状态结束
                this.loading = false;

                // 数据全部加载完成
                if (this.list.length >= 1) {
                this.finished = true;
                }
            }, 500);
        },
         onClick(index, title) {
                if(index==0){
                    this.getMyOrder()
                }else if(index==1){

                }
                },
        //获取我的预约
        getMyOrder(){
          myorder().then(res=>{
              if(res.data.code==200){
                this.orderlist=res.data.data.records
              }else{
                  this.orderlist=[]
              }
                  this.orderfinished=true
          })
        },
        //取消
        cancelOrder(item){
            cancelorder({
                mainKay:item.mainKay,
                title:item.title,
            }).then(res=>{
                this.$toast({
                    message:res.data.code==200?res.data.data:res.data.data.msg,
                    type:res.data.code==200?"success":'fail', 
                  })
                  this.getMyOrder()

            })
            // this.$router.push({name:'Renewing'})

        },
       
    }
}
</script>
<style lang="scss" scoped>
 #my{
     width: 100%;
     height: 100%;
     .van-list{
         .van-cell{
            .imgbox{
                float: left;
                width: 40%;
                img{
                width: 92px;
                height: 92px;
            }
            }
            .infobox{
                float: left;
                width: 60%;
                p{
                    line-height: 16px;
                }
            }
            
         }
     }
 }
</style>